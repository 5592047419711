<div class="container-fluid">
  <div class="row">
      <div class="col-6 p-0 border-end" style="height: calc(100vh - 100px)" [style.width]="isCollapsed ? '65px' : '178px'" [class.border-end]="!isCollapsed">
      <div class="sidenav-header">
        <i class="fa cursor-pointer"
           [ngClass]="!isCollapsed ? 'fa-angle-left' : 'fa-angle-right'"
           (click)="collapse()"
           style="color: #445259; font-size: 18px; font-weight: bold"></i>
      </div>
      <mat-nav-list class="first">
        @for (item of menuItems(); track item) {
          <a mat-list-item
             [title]="item.extern ? item.route : ''"
             (click)="isCollapsed ? collapse() : null; !item.extern ? setActiveSubMenu(item.route) : openExternalUrl(item.route);"
             [class.active]="item.label.toLowerCase() === activeSubMenu.toLowerCase()">
            <i matListItemIcon class="fa {{ item.icon }}" style="color: #445259; width: 45px"></i>
            <span matListItemTitle class="label" [class.fw-bolder]="item.label.toLowerCase() === activeSubMenu.toLowerCase()" *ngIf="!isCollapsed">{{ item.label }}</span>
          </a>
        }
      </mat-nav-list>
      <div class="sidenav-footer fixed-bottom">
        <a routerLink="/home">
          <img alt="{{pageTitle}}" class="{{logo}}" src="assets/images/{{logo}}.png">
        </a>
      </div>
    </div>
    <div class="col-6 p-0 ps-3" style="width: 221px" [class.d-none]="isCollapsed">
      @switch (activeSubMenu) {
        @case ('stammdaten') {
          <div class="sidenav-header px-3 pb-0">
            <fa-icon [icon]="faXmark" class="cursor-pointer float-end" style="color: #445259; font-size: 14px" (click)="collapse()"></fa-icon>
            <div class="headline">
              <div style="text-transform: capitalize">{{activeSubMenu}}</div>
              <div class="py-2 border-bottom"></div>
              <div class="home-button">
                <fa-icon [icon]="faHome" class="cursor-pointer" style="color: #445259; font-size: 14px" (click)="onNavigate('/home')"></fa-icon>
              </div>
            </div>
          </div>
          <mat-nav-list class="second">
            @for(item of menu[activeSubMenu]; track item) {
              <a mat-list-item class="py-1" (click)="onNavigate(item.route)">
                <span matListItemTitle class="label" [class.fw-bolder]="router.url.indexOf(item.route) !== -1">{{ item.name }}</span>
              </a>
            }
            <a mat-list-item class="py-1 mt-5" (click)="onNavigate('/faq')">
              <span matListItemTitle class="label" [class.fw-bolder]="router.url === '/faq'">Hilfe & FAQ</span>
            </a>
          </mat-nav-list>
        }
        @case ('datenupload') {
          <div class="sidenav-header px-3 pb-0">
            <fa-icon [icon]="faXmark" class="cursor-pointer float-end" style="color: #445259; font-size: 14px" (click)="collapse()"></fa-icon>
            <div class="headline">
              <div style="text-transform: capitalize">{{activeSubMenu}}</div>
              <div class="py-2 border-bottom"></div>
              <div class="home-button">
                <fa-icon [icon]="faHome" class="cursor-pointer" style="color: #445259; font-size: 14px" (click)="onNavigate('/home')"></fa-icon>
              </div>
            </div>
          </div>
          <mat-nav-list class="second">
            @for(item of menu[activeSubMenu]; track item) {
              <a mat-list-item class="py-1" (click)="onNavigate(item.route)">
                <span matListItemTitle class="label" [class.fw-bolder]="router.url.indexOf(item.route) !== -1">{{ item.name }}</span>
              </a>
            }
          </mat-nav-list>
        }
      }
    </div>
  </div>
</div>

