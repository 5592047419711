import {Component, ViewChild, OnInit, DestroyRef, inject, signal, computed} from '@angular/core';
import { DropDownListComponent, DropDownListModule } from 'smart-webcomponents-angular/dropdownlist';
import {
  faArrowRightFromBracket,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Router, RouterOutlet } from '@angular/router';
import { RolesService } from '../../../utils/roles.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { CompanySelectorService, DropdownItem } from '../../../shared/services/company-selector.service';
import { tap } from 'rxjs/operators';
import { map, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { sortByField } from '../../../utils/sort.utils';
import { capitalizeFirstLetter } from '../../../utils/misc.utils';
import { NavigationToggleService } from '../../../shared/services/navigation-toggle.service';
import { Favicons } from '../../../app.constants';
import { environment } from '../../../../environments/environment';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {SidenavComponent} from '../../../shared/components/sidenav/sidenav.component';
import {DataService} from '../../../api/services/data.service';

@Component({
  selector: 'app-frame',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    DropDownListModule,
    FaIconComponent,
    RouterOutlet,
    MatToolbarModule,
    MatSidenavContainer,
    MatSidenav,
    MatSidenavContent,
    SidenavComponent
  ],
  templateUrl: './frame.component.html',
  styleUrl: './frame.component.scss'
})
export class FrameComponent implements OnInit {

  collapsedSubmenu = true;
  collapsed: any = signal(false);
  sidenavWidth = computed(() => this.collapsed() ? '70px' : '400px');
  contentWidth = computed(() => this.collapsed() ? 'calc(100vw - 70px)' : 'calc(100vw - 400px)');

  private readonly keycloak = inject(KeycloakService);
  private cookieService = inject(CookieService);
  protected router = inject(Router);
  private rolesService = inject(RolesService);
  private companySelectorService = inject(CompanySelectorService);
  private navigationToggleService = inject(NavigationToggleService);
  private destroyRef = inject(DestroyRef);
  private title = inject(Title);
  private dataService = inject(DataService);

  @ViewChild('dropdownCompaniesRef', { read: DropDownListComponent, static: false }) dropdownCompaniesRef!: DropDownListComponent;

  protected readonly faLogout = faArrowRightFromBracket;
  protected readonly faQuestionCircle = faQuestionCircle;

  protected env = environment;

  companiesList$ = this.companySelectorService.companies$.pipe(
    tap(() => this.initCompaniesDropDown())
  );
  selectedCompanyIndex$: Observable<number[]> = this.companySelectorService.selectedCompanyIndex$.pipe(
    map((index: number | null) => index === null ? [] : [index])
  );
  selectedCompany: DropdownItem | null = null;

  menuUser: any[] = [];
  readonly companySelectEnabledRoutes = ['/home', '/filialen', '/produkte'];
  readonly open$ = this.navigationToggleService.open$;

  get pageTitle() {
    let title = `Stammdatenverwaltung`;
    this.dataService.currentData.subscribe((data: any) => {
      if (data?.title) {
        title = capitalizeFirstLetter(data.title);
      }
    });
    return title;
  }

  get logoSmartTomm() {
    return this.env.envName === 'test' || this.env.envName === 'stage' ? '.test' : '';
  }

  sidebarCollapsed(collapsed: any) {
    this.collapsed.set(!collapsed);
  }

  submenuCollapsed(collapsed: any) {
    this.sidenavWidth = computed(() => !collapsed ? '70px' : '400px');
  }

  ngOnInit() {
    this.initFavicon();
    this.initTitle();
    this.collapsed.set(true);
    this.rolesService.setAllUserRolesCookies();
  }

  onNavigate(path: string) {
    path = path || '/home';
    const companyId = this.companySelectorService.getCompanyId();
    if (!companyId) {
      return;
    }

    this.dropdownCompaniesRef.disabled = false;
    this.cookieService.set('verwaltung', path);
    this.router.navigateByUrl(path);

    if(path === '/home') {
      this.navigationToggleService.open();
    } else {
      this.navigationToggleService.close();
    }
  }

  onSidebarToggle() {
    this.navigationToggleService.toggle();
  }

  private initCompaniesDropDown() {
    setTimeout(() => (this.dropdownCompaniesRef.disabled = false));
  }

  private initFavicon() {
    const tenant = this.rolesService.getCurrentClient();

    document.getElementById('favicon')?.setAttribute('href', tenant === 'sug' ? Favicons.Sug : Favicons.Base);
    document.getElementById('favicon16')?.setAttribute('href', tenant === 'sug' ? Favicons.Sug16 : Favicons.Base16);
    document.getElementById('favicon32')?.setAttribute('href', tenant === 'sug' ? Favicons.Sug32 : Favicons.Base32);
  }

  private initTitle() {
    const tenant = this.rolesService.getCurrentClient();
    const title = tenant === 'sug' ? 'S&G Stammdatenverwaltung' : tenant === 'ems' ? 'ems Stammdatenverwaltung': 'Stammdatenverwaltung';
    this.title.setTitle(title);
  }

  async logout() {
    await this.keycloak.logout();
  }
}
