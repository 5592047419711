import { UmlautMap } from '../app.constants';

export function getFirstElement(value: any) {
  return Array.isArray(value) && value[0] ? value[0] : value;
}

export function replaceUmlaute(str: string) {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = UmlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('['+Object.keys(UmlautMap).join('|')+']','g'),
      (a) => UmlautMap[a]
    ).replace(new RegExp('[.|,|&|$| |(|)|%|§|"|!|-]','g'), '');
}

export function mapObjectToGridFilter(_obj: any) {
  const obj = _obj.map((val: any) => { return { [val.value]: val.label } } );
  return JSON.stringify(obj).replace('[', '').replace(']', '').replaceAll('},{', ',')
}

export function ensureHttps(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}

export function capitalizeFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeFirstLetterAlt(str: string): string {
  if (!str) return str;
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}
