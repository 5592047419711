import {ApiResponse, BaseHttpService, Company, Contact} from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class ContactService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getContact(id: number): Observable<ApiResponse<Contact>> {
    this.invalidateCache();
    const url = `contact/${id}`;
    const queryParams = {
      'loader': false,
      'expand[0]': 'consents',
      'expand[1]': 'updatedBy',
      'expand[2]': 'employer',
    };
    return this.get(url, queryParams);
  }

  getList(queryParams: any): Observable<ApiResponse<Contact>> {
    this.invalidateCache();
    return this.get('contacts', queryParams);
  }

  getContactsByQuery(query?: string, page = 1, pageSize = 5, types?: string | string[]): Observable<ApiResponse<Company[]>> {
    const queryParams: any = {
      'expand[0]': 'employer',
      page,
      pageSize,
      sort: '+last_name',
      ...(query ? { query } : {}),
      ...(Array.isArray(types)
          ? types.reduce((acc: any, curr: string, i: number) => {
            acc[`filter[employer_business_relationship][in][${i}]`] = curr;
            return acc;
          }, {})
          : {}
      ),
      ...(typeof types === 'string' ? {'filter[employer_business_relationship][eq]': types} : {}),
    };
    return this.get('contacts', queryParams);
  }

  getContactsForCondition(query?: string, page = 1): Observable<ApiResponse<Contact[]>> {
    this.invalidateCache();
    const queryParams = {
      pageSize: 5,
      'expand[0]': 'employer',
      page,
      /* ToDo: 2025-03-31 BK Search limited for conditions contact?
      'filter[employer_business_relationship][in][0]': 'agency',
      'filter[employer_business_relationship][in][1]': 'supplier',
      'filter[employer_business_relationship][in][2]': 'partner',
      'filter[employer_business_relationship][in][3]': 'applicant',
      'filter[employer_business_relationship][in][4]': 'competitor',
      'filter[employer_business_relationship][in][5]': 'mediaPartner',

       */
      ...(query ? {
        'filter_connector': 'and',
        'filter[name][like]': `%${query}%`
      } : {}),
    };

    return this.get('contacts', queryParams);
  }

  getContactByEmployerId(query?: string, page = 1, pageSize = 5): Observable<ApiResponse<Contact[]>> {
    const queryParams: any = {
      page,
      pageSize,
      sort: '+last_name',
      'filter[employer_id][eq]': this.cookieService.get('company_id'),
      ...(query ? { query } : {}),
    };

    return this.get('contacts', queryParams);
  }

  getAllContactsByEmployerId(employerId?: number): Observable<Contact[]> {
    const queryParams = {
      'filter[employer_id][eq]': employerId,
    };

    return this.get('contacts', queryParams);
  }

  createContact(body: any): Observable<any> {
    const params = {
      'loader': false,
      'expand[0]': 'employer',
      'expand[1]': 'createdBy'
    };
    return this.post('contacts', body, {}, params);
  }

  updateContact(id: number, body: any): Observable<any> {
    const params = {
      'loader': false,
      'expand[0]': 'employer',
      'expand[1]': 'updatedBy',
      'expand[2]': 'consents',
    };
    return this.post(`contact/${id}`, body, {}, params);
  }

  deleteContact(id: number): Observable<any> {
    const params = {
      'loader': false,
      'expand[0]': 'updatedBy'
    };
    return this.delete(`contact/${id}`, params);
  }

  getAllResponsibilities(): Observable<any> {
    let queryParams = {};
    return this.getAll('responsibilities', queryParams);
  }

}
