<div class="px-4 py-2">

  <!--
  <div class="container px-0 border-bottom">
    <div class="row flex-nowrap" style="font-size: 14px">
      <div class="col p-3 bold text-nowrap text-center" style="font-size: 16px">Leider ist ein Fehler aufgetreten!</div>
    </div>
  </div>
  <div class="dialog-header">
    Leider ist ein Fehler aufgetreten!
    <button class="close-button" matDialogClose>&nbsp;</button>
  </div>-->
  <div class="dialog-content pb-4 {{data.classNames}}">
    <div class="container-fluid p-0 text-center">
      @for (error of data.errorList; track error) {
        <div class="mb-3">
          <div class="text-danger error-status">Status {{error.status}}: {{error.title}}</div>
          <div class="mt-1 error-description">{{error.description}}</div>
          @if (error.errorResponse) {
            <div class="mt-3 error-sub-title">Meldung des Systems</div>
            <div class="mt-1">{{error.errorResponse.error?.message}}</div>
            <div class="mt-1 error-url">{{error.errorResponse?.url}}</div>
          }
          <div class="mt-3 error-sub-title">Was ist zu tun?</div>
          <div class="mt-1">{{error.action}}</div>
        </div>
      }
    </div>
    <div class="container mt-4 mb-4 text-center">
      <smart-button
        theme="custom"
        mat-dialog-close="dismiss"
        class="smart-cancel-button cancel"
      >
        Schließen
      </smart-button>
    </div>
  </div>

</div>
