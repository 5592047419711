import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { ButtonModule } from 'smart-webcomponents-angular/button';
import { NgIf } from '@angular/common';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'app-error-dialog',
  imports: [
    ButtonModule,
    MatDialogClose,
    NgIf,
    FaIconComponent
  ],
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  protected readonly faCheck = faCheck;
  protected readonly faXmark = faXmark;
}
