import {Component, EventEmitter, inject, input, Input, OnInit, Output, output, signal} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {RolesService} from '../../../utils/roles.service';
import {sortByField} from '../../../utils/sort.utils';
import {CookieService} from 'ngx-cookie-service';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faHome, faXmark} from '@fortawesome/free-solid-svg-icons';
import {DataService} from '../../../api/services/data.service';

export type MenuItem = {
  icon: string;
  label: string;
  route: string;
  extern?: boolean;
}

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [CommonModule, MatListModule, MatIconModule, RouterLink, RouterLinkActive, FaIconComponent, NgOptimizedImage],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent implements OnInit {
  private dataService = inject(DataService);
  private rolesService = inject(RolesService);
  private cookieService = inject(CookieService);
  protected router = inject(Router);

  protected readonly faXmark = faXmark;
  protected readonly faHome = faHome;

  menuUser: any[] = [];
  menu: any = [];
  activeSubMenu = 'stammdaten';

  isCollapsedSubMenu = false;

  submenuCollapse = output<boolean>();
  sidebarCollapsed = output<boolean>();
  @Input() isCollapsed?: boolean | null = true;

  menuItems = signal<MenuItem[]>([
    {
      icon: 'fa-database',
      label: 'Stammdaten',
      route: 'stammdaten'
    },{
      icon: 'fa-cloud-upload',
      label: 'Datenupload',
      route: 'datenupload'
    },{
      icon: 'st-smartTomm',
      label: 'smartTomm',
      route: 'https://smarttomm.sommer-gossmann.com',
      extern: true
    }
  ])

  get pageTitle() {
    return 'Stammdatenverwaltung';
  }

  get logo() {
    return this.rolesService.getCurrentClient() === 'sug' ? !this.isCollapsed ? 'sug-logo' : 'sug-logo-small' : !this.isCollapsed ? 'ems-logo' : 'ems-logo-small';
  }

  openExternalUrl(url: string): void {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null;
    }
  }

  ngOnInit() {
    this.rolesService.setMenuUser();
    this.menuUser = this.rolesService.getMenuUser().sort(sortByField('name'));
    this.menu['stammdaten'] = this.menuUser.filter((items: any) => items.kind === 'stammdaten');
    this.menu['datenupload'] = this.menuUser.filter((items: any) => items.kind === 'datenupload');

    console.log('OnInit isCollapsed', Boolean(this.isCollapsed));

  }

  onNavigate(path: string) {
    path = path || '/home';
    this.cookieService.set('verwaltung', path);
    this.router.navigateByUrl(path);
  }

  collapseSubMenu() {
    this.isCollapsedSubMenu = !this.isCollapsedSubMenu;
    this.submenuCollapse.emit(this.isCollapsedSubMenu);
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.sidebarCollapsed.emit(!this.isCollapsed);
  }

  setActiveSubMenu(menu: any) {
    this.activeSubMenu = menu || 'stammdaten';
    this.dataService.updateData({ title: this.activeSubMenu });
  }
}
