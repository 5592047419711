export const environment = {
  production: false,
  envName: "test",
  PERMISSION_DELETE: ['Bernd Krech', 'Chiara Riley', 'chiara.riley', 'test user'],
  HIDE_CUSTOM_FILTER: false,
  API_URL_BASE: "https://test-stammdaten.em-sourcing.com/",
  API_URL_SUG: "https://test-stammdaten.sommer-gossmann.com/",
  API_ROOT: "api/",
  API_VERSION: "",
  API_CHANGELOG: "[1.44.14] - 2025-03-28",
  SMARTTOMM_URL_BASE: "https://smarttomm.em-sourcing.com/",
  SMARTTOMM_URL_SUG: "https://smarttomm.sommer-gossmann.com/",
  SMARTTOMM_URL_ERROR: "failed/Stammdaten?status=",
  LOGIN_URL_BASE: "https://login.em-sourcing.com/",
  LOGIN_URL_SUG: "https://login.sommer-gossmann.com/",
  SSO_REALM_BASE: "smarttomm",
  SSO_REALM_SUG: "sug",
  SSO_CLIENT_ID_BASE: "stammdaten",
  SSO_CLIENT_ID_SUG: "stammdatenmanagement",
  sentry: {
    dsn: 'https://f5bf70da1c4b2771d568cf452d7f1a9a@sentry.sommer-gossmann.com/3',
    release: '7aa6b193'
  }
};
